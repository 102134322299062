<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">现有安消防系统硬件设施信息</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="消防系统名称" prop="XFXTMC">
                    <el-input
                        v-model="formdata.dataDic.XFXTMC"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="用途分类">
                    <el-input
                        v-model="formdata.dataDic.YTFL"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="投资总额" prop="TZJE">
                    <el-input
                        v-model="formdata.dataDic.TZJE"
                        :disabled="!isEdit"
                    >
                        <template slot="append">万元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="中央财政经费" prop="ZYCZJF">
                    <el-input
                        v-model="formdata.dataDic.ZYCZJF"
                        :disabled="!isEdit"
                    >
                        <template slot="append">万元</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="监控探头数量" prop="JKTTSL">
                    <el-input
                        v-model="formdata.dataDic.JKTTSL"
                        :disabled="!isEdit"
                    >
                        <template slot="append">个</template>
                    </el-input>
                </el-form-item>
                <el-form-item label="建成时间">
                    <el-date-picker
                        v-model="formdata.dataDic.JCSJ"
                        type="month"
                        value-format="yyyy-MM-dd"
                        placeholder="选择日期"
                        :disabled="!isEdit"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="系统构成描述" class="from-item-block">
                    <el-input
                        v-model="formdata.dataDic.XTGCMS"
                        type="textarea"
                        :maxlength="500"
                        show-word-limit
                        :autosize="{ minRows: 6 }"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <basic-upload
                    ref="uploadFile"
                    :isEdit="isEdit"
                    :itemId="itemId"
                    :metaData.sync="formdata.metaJson"
                    basicType="document"
                    :maxSize="500"
                ></basic-upload>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../0_com_js/info_mixin.js";
export default {
    name: "xyaxfxtyjssxx",
    mixins: [infoMixin],
    data() {
        return {
            isEdit: false,
            saveButton: true,
            formdata: {
                itemCode: "1301",
                dataDic: {
                    JCSJ: "",
                    TZJE: "",
                    JKTTSL: "",
                    XFXTMC: "",
                    YTFL: "",
                    XTGCMS: "",
                },
            },
            rules: {
                // JCSJ: [
                //     { required: true, message: '请选择硬件设施建成时间', trigger: 'blur' }
                // ],
                // TZJE: [
                //     { required: true, message: '请填写投资金额', trigger: 'blur' }
                // ],
                // JKTTSL: [
                //     { required: true, message: '请填写监控探头数量', trigger: 'blur' }
                // ]
            },
            enumData: [],
        };
    },
    mounted() {},
    methods: {},
};
</script>

<style></style>
